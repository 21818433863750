














import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class InternalServerError extends Vue {
  ok(): void {
    window.location.assign(process.env.BASE_URL||"");
  }
}
